import { PageTextAddIcon } from '@fingertip/icons'
import NextImage from 'next/image'
import React from 'react'

import { FileExtensionIcon } from '@/components/shared/file-extension-icon'
import { Spinner } from '@/components/shared/spinner'
import { UploaderAsset } from '@/lib/hooks/use-asset-uploader'
import { cn, getFilenameExtension, isImageExtension } from '@/lib/utils'
import { fileLoader, fileSrc } from '@/lib/utils/image-loader'

type Props = {
  hidePreview?: boolean
  asset: UploaderAsset
  size: number
  loading?: boolean
  loader?: any
  className?: string
  isLink?: boolean
}

export const AssetUploadItemPreview = ({
  size,
  asset,
  hidePreview,
  className,
  loading,
  loader = fileLoader,
  isLink,
}: Props) => {
  const content = (
    <div
      className={cn('overflow-hidden border border-border', className)}
      style={{ height: size, width: size }}
    >
      {loading ? (
        <div
          className="flex items-center justify-center bg-muted text-muted-foreground"
          style={{ height: size, width: size }}
        >
          <Spinner size={size / 2} />
        </div>
      ) : hidePreview ? (
        <div
          className="flex items-center justify-center bg-muted text-muted-foreground"
          style={{ height: size, width: size }}
        >
          <PageTextAddIcon />
        </div>
      ) : isImageExtension(getFilenameExtension(asset.fileName)) ? (
        <NextImage
          src={`${asset.bucket}/${asset.src}`}
          alt={asset.fileName}
          className="object-cover"
          width={asset?.width || 500}
          height={asset?.height || 500}
          loader={loader}
          style={{ width: size, height: size }}
        />
      ) : (
        <div
          className="flex items-center justify-center bg-muted text-muted-foreground"
          style={{ height: size, width: size }}
        >
          <FileExtensionIcon
            size={size / 2}
            extension={getFilenameExtension(asset.fileName)}
          />
        </div>
      )}
    </div>
  )

  // If downloadUrl is provided, wrap the content in an anchor tag
  if (isLink) {
    const url = fileSrc({
      bucket: asset.bucket,
      src: asset.src,
    })

    return (
      <a href={url} download={asset.fileName} className="block" target="_blank">
        {content}
      </a>
    )
  }

  // Otherwise, return the content as is
  return content
}
