'use client'

import React from 'react'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

type Props = {
  message: React.ReactNode
  children: React.ReactNode
  align?: 'start' | 'center' | 'end'
  delayDuration?: number
}

export const TooltipWrapper = ({
  message,
  children,
  align,
  delayDuration = 300,
}: Props) => {
  const [open, setOpen] = React.useState(false)

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  return (
    <TooltipProvider>
      <Tooltip
        open={open}
        onOpenChange={handleOpenChange}
        delayDuration={delayDuration}
      >
        <TooltipTrigger asChild>{children}</TooltipTrigger>

        <TooltipContent
          align={align}
          className="max-w-[calc(75vw)] text-wrap md:max-w-[calc(45vw)]"
        >
          {message}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
