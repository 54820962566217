import { FileBendIcon } from '@fingertip/icons'
import React from 'react'

import { cn } from '@/lib/utils'

type Props = {
  size: number
  extension: string
}
export const FileExtensionIcon = ({ size, extension }: Props) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        position: 'relative',
      }}
    >
      <FileBendIcon strokeWidth={1} width={size} height={size} />
      <div
        className={cn(
          'absolute flex items-center justify-center rounded-sm bg-muted-foreground px-[1px] py-[1px] text-muted',
        )}
        style={{
          fontSize: size / 4,
          top: `calc(50% - 0.5rem - 1px)`,
          left: 0,
          width: size,
        }}
      >
        <strong>.</strong>
        {extension}
      </div>
    </div>
  )
}
